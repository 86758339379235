import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
      path: '/tx',
      name: 'tx',
      component: () => import(/* webpackChunkName: "about" */ '../views/TxView.vue')
},
  {
      path: '/bz',
      name: 'bz',
      component: () => import(/* webpackChunkName: "about" */ '../views/BangZhu.vue')
  },
{
    path: '/lx',
        name: 'lx',
    component: () => import(/* webpackChunkName: "about" */ '../views/LianXi.vue')
},
  {
    path: '/info/:type/:id',
    name: 'info',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/InfoView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
