import listData from '@/assets/tx.json';
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import "swiper/css/bundle";
import "swiper/css/autoplay";
import Footer from "@/components/Footer";
export default {
  name: 'HomeView',
  components: {
    Swiper,
    SwiperSlide,
    Footer
  },
  setup() {
    console.log("开始");
    return {
      modules: [Autoplay],
      autoplayOptions: {},
      listData
    };
  }
};